import React, { useState, useCallback } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Box } from '@mui/material';
import { EditOutlined, PictureAsPdfOutlined, TextSnippetOutlined, EditNoteSharp, DriveFileRenameOutline, } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAllDocumentsOpen, setEditDemandDetailsOpen, } from '../redux/slices/demandDomSlice';
import EditDocuments from './EditDocuments';
import EditDemandDetails from './DemandForms/EditDemandDetails';
import SaveAnimationDialog from '../dialogs/SaveAnimationDialog';
import { userHasPermission } from '../common';
import { canComposeDemand } from '../DemandComponents/helpers';
import { useGetPdfUrlQuery } from '../services/pdf/pdfApi';
import { setEditDemandNotes, } from '../redux/slices/globalDialogueSlice';
import AnimatedDialog from '../dialogs/AnimatedDialogWrapper';
import { useParams } from 'react-router-dom';

const EditMenu = ({
  disabled,
  showTooltip,
  isEditDocumentsDisabled = false,
  editDocumentsToolTipText = '',
  useDemandNotesEnabled = false,
}) => {
  const { documentId } = useParams();
  const { documentMetaData } = useSelector((state) => state.Document);
  const { user, userData } = useSelector((state) => state.User);
  const { demandDetailsEditOpen } = useSelector((state) => state.DemandDom);

  const { data: { demandS3Url } = {} } = useGetPdfUrlQuery(documentId, { skip: !documentId, refetchOnReconnect: true });

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  // Handlers for menu actions
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleDocumentsEditOpen = useCallback(() => { closeMenu(); dispatch(setEditAllDocumentsOpen(true)); }, [dispatch]);

  const handleDemandLetterEditOpen = useCallback(() => {
    closeMenu();
    dispatch(setEditDemandDetailsOpen(true));
  }, [dispatch]);

  const handleOpenEditNotes = useCallback(() => { closeMenu(); dispatch(setEditDemandNotes({ open: true, isOpenFromInventory: false, data: { documentId, user } })); }, [dispatch, documentId, user]);

  return (
    <div>
      <Tooltip
        title={
          disabled && showTooltip
            ? 'This demand has been approved for delivery or archived. It can no longer be edited.'
            : ''
        }
        arrow
      >
        <Button
          id="basic-button"
          aria-controls={anchorEl ? 'basic-menu' : undefined}
          aria-haspopup="true"
          onClick={openMenu}
          color="secondary"
          variant="outlined"
          startIcon={<EditOutlined />}
          disabled={disabled}
        >
          Edit
        </Button>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {canComposeDemand(userData, documentMetaData) &&
          [
            <MenuItem key="demand-details" onClick={handleDemandLetterEditOpen}>
              <ListItemIcon><TextSnippetOutlined /></ListItemIcon>
              <ListItemText primary="Demand details" />
            </MenuItem>,

            <Tooltip key="documents" title={isEditDocumentsDisabled ? editDocumentsToolTipText : ''} arrow placement="left">
              <div>
                <MenuItem
                  onClick={handleDocumentsEditOpen}
                  disabled={isEditDocumentsDisabled} 
                >
                  <ListItemIcon><PictureAsPdfOutlined /></ListItemIcon>
                  <ListItemText primary="Documents" />
                </MenuItem>
              </div>
            </Tooltip>,
          ]
        }

        {useDemandNotesEnabled && (
          <MenuItem onClick={handleOpenEditNotes} disabled={!userHasPermission('DemandCompose', userData)}>
            <ListItemIcon><EditNoteSharp /></ListItemIcon>
            <ListItemText primary="Notes" />
          </MenuItem>
        )}
      </Menu>

      <EditDocuments />

      {userHasPermission('DemandCompose', userData) && (
        <AnimatedDialog open={demandDetailsEditOpen} >
          <EditDemandDetails />
        </AnimatedDialog>
      )}

    </div>
  );
};

export default EditMenu;
