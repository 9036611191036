import { Chip, Stack } from '@mui/material'
import React from 'react'

const StrengthOfCaseTags = ({ tags }) => {
    return (
        <Stack direction="row" spacing={1}>
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems={"center"}
                sx={{
                    ml: 0,
                    rowGap: .5,  // Space between rows
                    '& > *': {
                        marginRight: '8px', // Space between chips
                    },
                    '& > *:last-child': { marginRight: 0 }, // Remove right margin for the last chip
                }}
            >
                {
                    tags.map((item, index) => (
                        < Chip variant='outlined' label={item} key={index} />
                    ))
                }
            </Stack>
        </Stack>
    )
}

export default StrengthOfCaseTags

