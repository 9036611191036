import React, { useEffect, useMemo } from 'react'
import DetailCard from './DetailCard'
import {
    AutoAwesome,
    CheckCircleOutlineOutlined,
    ErrorOutlineOutlined,
    RefreshOutlined,
    WarningAmberOutlined,
    HourglassEmptyOutlined
} from '@mui/icons-material'
import { Button, Chip, Grid, Stack, Tooltip, Typography, CircularProgress } from '@mui/material'
import { grey } from '@mui/material/colors'
import { deepPurple } from '@mui/material/colors'
import { theme } from '../Theme'
import { useGenerateDemandScoreQuery, useLazyGenerateDemandScoreQuery } from '../services/demandScore/demandScoreApi'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setToast } from '../redux/slices/globalToastSlice'

const IndividualScores = ({ insights }) => {
    // Get medicals and dateOfLoss for billBeforeDOL calculation
    const { medicals } = useSelector((state) => state.Medicals);
    const { documentData } = useSelector((state) => state.Document);
    const dateOfLoss = documentData?.claimInfo?.dateOfLoss || null;

    // Calculate if there are any bills before DOL
    const hasBillBeforeDOL = useMemo(() => {
        const hasMedicals = medicals?.medicalTreatments && medicals.medicalTreatments.length > 0;
        if (!hasMedicals || !dateOfLoss) return null;
        const dolDate = new Date(dateOfLoss);

        return medicals.medicalTreatments.some(treatment => {
            if (!treatment.treatmentDate) return false;
            const treatmentDate = new Date(treatment.treatmentDate);
            return treatmentDate < dolDate;
        });
    }, [medicals, dateOfLoss]);


    return (
        insights.map((insight, index) => {
            const mappedName = scoreNameMapping[insight.score_name];

            // Special handling for billBeforeDOL
            let mappedScore;
            if (mappedName === 'billBeforeDOL') {
                if (hasBillBeforeDOL === null) {
                    // If we can't calculate (no medical treatments or no DOL), show as pending
                    mappedScore = 'pending';
                } else {
                    mappedScore = hasBillBeforeDOL ? 'bad' : 'good';
                }
            } else {
                // For other insights, use the API's score mapping
                mappedScore = scoreValueMapping[insight.score] || 'pending';
            }

            const scoreInfo = scoreLanguage[mappedName];
            const colorInfo = colorMapping[mappedScore];

            if (!scoreInfo || !colorInfo) return null;

            // Determine the display label
            let displayLabel;
            if (mappedName === 'billBeforeDOL') {
                displayLabel = mappedScore === null ? 'Pending' : scoreInfo[mappedScore];
            } else {
                displayLabel = insight.description || scoreInfo[mappedScore];
            }

            return (
                <Stack
                    key={index}
                    sx={{
                        flexBasis: "calc(50% - 8px)",
                        flexGrow: "1 1 50%",
                        minWidth: 175,
                        maxWidth: { xs: '100%' }
                    }}
                >
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        {scoreInfo.label}
                    </Typography>
                    <Tooltip
                        title={mappedScore === 'pending'
                            ? scoreInfo.pendingTooltip
                            : scoreInfo.tooltip}
                        placement='top'
                        arrow
                    >
                        <Chip
                            label={displayLabel}
                            icon={colorInfo.icon}
                            variant="outlined"
                            sx={{
                                justifyContent: "flex-start",
                                backgroundColor: colorInfo.fill,
                                borderColor: colorInfo.border,
                                color: colorInfo.text,
                                py: .5,
                                "& .MuiChip-icon": {
                                    color: colorInfo.border
                                }
                            }}
                        />
                    </Tooltip>
                </Stack>
            );
        })
    );
};

const PendingScores = () => {
    // The expected order of display
    const scoreTypesInOrder = [
        'billBeforeDOL',
        'billCompleteness',
        'billContinuity',
        'demandVelocity',
        'earlyBillSeverity'
    ];

    return (
        scoreTypesInOrder.map((scoreType, index) => {
            const scoreInfo = scoreLanguage[scoreType];

            return (
                <Stack
                    key={index}
                    sx={{
                        flexBasis: "calc(50% - 8px)",
                        flexGrow: "1 1 50%",
                        minWidth: 175,
                        maxWidth: { xs: '100%' }
                    }}
                >
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        {scoreInfo.label}
                    </Typography>
                    <Tooltip
                        title={scoreInfo.pendingTooltip}
                        placement='top'
                        arrow
                    >
                        <Chip
                            label="Pending"
                            icon={<HourglassEmptyOutlined />}
                            variant="outlined"
                            sx={{
                                justifyContent: "flex-start",
                                backgroundColor: "#E3F2FD",
                                borderColor: theme.palette.info.main,
                                color: theme.palette.text.secondary,
                                py: .5,
                                "& .MuiChip-icon": {
                                    color: theme.palette.info.main
                                }
                            }}
                        />
                    </Tooltip>
                </Stack>
            );
        })
    );
};

const DemandScoreCard = () => {
    const { documentId } = useParams();
    const dispatch = useDispatch();

    // Get the demand score data from the slice that has a matcher to funnel the query and the lazy query (manual refresh) into a shared state
    const { demandScoreData, demandScoreDataLoading, demandScoreDataError } = useSelector((state) => state.DemandScore);
    const currentVersion = demandScoreData?.version || 1;

    // Onload use 1 as the version to get the most recently generated demand score
    const { error: dsError } = useGenerateDemandScoreQuery({ documentId, version: 1 });

    // Use the lazy query to generate the demand score on refresh CTA
    const [generateDemandScore] = useLazyGenerateDemandScoreQuery();

    // Format current date for the refresh timestamp
    const formatCurrentDate = () => {
        const now = new Date();
        const date = now.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const time = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        return `${date} ${time}`;
    };

    const timestamp = formatCurrentDate();

    // Get the new version of the demand score on refresh CTA click
    const handleRefresh = async () => {
        try {
            dispatch(setToast({
                isOpen: true,
                message: "Refreshing demand score...",
                severity: 'info'
            }));
            await generateDemandScore({ documentId, version: currentVersion + 1 }).unwrap();
        } catch (error) {
            dispatch(setToast({
                isOpen: true,
                message: "Error refreshing demand score",
                severity: 'error'
            }));
        }
    };

    useEffect(() => {
        if (demandScoreDataError) {
            dispatch(setToast({
                isOpen: true,
                message: "Error loading demand score",
                severity: 'error'
            }));
        }
    }, [demandScoreDataError, dispatch]);

    const isLoading = demandScoreDataLoading;

    return (
        <Grid item
            sx={{ flex: "2", minWidth: "350px", maxWidth: { xs: '100%', lg: '49%' }, }}
        >
            <DetailCard
                icon={<AutoAwesome sx={{ color: deepPurple[900] }} />}
                label="DemandIQ"
                action={
                    <Button
                        variant='text'
                        color='primary'
                        size='small'
                        onClick={handleRefresh}
                        disabled={isLoading}
                        endIcon={<RefreshOutlined sx={{
                            animation: isLoading ? 'spin 1s linear infinite' : 'none',
                            '@keyframes spin': {
                                '0%': { transform: 'rotate(0deg)', },
                                '100%': { transform: 'rotate(360deg)' }
                            }
                        }} />}
                        sx={{ mr: 1 }}
                    >
                        {timestamp}
                    </Button>
                }
            >
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent={"space-between"}
                    sx={{
                        rowGap: .5,
                        columnGap: 1,
                        alignItems: "flex-start",
                    }}
                >
                    {isLoading ? (
                        <Stack direction={'row'} alignItems={'center'} spacing={1} justifyContent={'center'} sx={{ width: '100%' }} pt={3} >
                            <CircularProgress size={24} />
                            <Typography variant="body2" color={grey[700]}> Refreshing </Typography>
                        </Stack>
                    ) : demandScoreData?.insights && demandScoreData.insights.length > 0 ? (
                        <IndividualScores insights={demandScoreData.insights} />
                    ) : (
                        <PendingScores />
                    )}
                </Stack>
            </DetailCard>
        </Grid>
    )
}

export default DemandScoreCard

// Utils
// Updated scoreLanguage object with new tooltips
const scoreLanguage = {
    billCompleteness: {
        label: 'Bill completeness',
        tooltip: 'AI evaluates if medical records have matching bills',
        pendingTooltip: 'AI evaluates if medical records have matching bills\n\nTo see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Low confidence',
        med: 'Med confidence',
        good: 'High confidence',
        pending: 'Pending'
    },
    billContinuity: {
        label: 'Bill continuity',
        tooltip: 'AI checks for consistent billing from injury to demand submission',
        pendingTooltip: 'AI checks for consistent billing from injury to demand submission\n\nTo see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Gaps identified',
        med: 'Initial treatment delay',
        good: 'No gaps',
        pending: 'Pending'
    },
    demandVelocity: {
        label: 'Demand velocity',
        tooltip: 'AI calculates accesses the case complexity and the relative speed from last treatment date to demand submission',
        pendingTooltip: 'AI calculates accesses the case complexity and the relative speed from last treatment date to demand submission\n\nTo see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Slow',
        med: 'Average',
        good: 'Fast',
        pending: 'Pending'
    },
    earlyBillSeverity: {
        label: 'Early bill severity',
        tooltip: 'AI assesses the severity of initial medical treatments post-injury',
        pendingTooltip: 'AI assesses the severity of initial medical treatments post-injury\n\nTo see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Low',
        med: 'Medium',
        good: 'High',
        pending: 'Pending'
    },
    billBeforeDOL: {
        label: 'Bill before DOL',
        tooltip: 'AI flags billing charges before the reported injury date.',
        pendingTooltip: 'AI flags billing charges before the reported injury date.\n\nTo see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Detected',
        good: 'None',
        pending: 'Pending'
    },
};

// Map API score values to our internal score types
const scoreValueMapping = {
    'Good': 'good',
    'Bad': 'bad',
    'Neutral': 'med',
    'False': 'bad',
    'True': 'good',
    'Medium': 'med',
    'N/A': 'pending'
};

// Map API score_name values to our internal names
const scoreNameMapping = {
    'BILL_BEFORE_DOL': 'billBeforeDOL',
    'BILL_COMPLETENESS': 'billCompleteness',
    'DEMAND_VELOCITY': 'demandVelocity',
    'EARLY_BILLING_DENSITY': 'earlyBillSeverity',
    'TREATMENT_CONTINUITY': 'billContinuity'
};

const colorMapping = {
    bad: { text: theme.palette.text.primary, border: theme.palette.error.dark, fill: '#FDEDED', icon: <ErrorOutlineOutlined /> },
    med: { text: theme.palette.text.primary, border: theme.palette.warning.dark, fill: '#FFF4E5', icon: <WarningAmberOutlined /> },
    good: { text: theme.palette.text.primary, border: theme.palette.success.dark, fill: '#EDF7ED', icon: <CheckCircleOutlineOutlined /> },
    pending: { text: theme.palette.text.secondary, border: theme.palette.info.main, fill: '#E3F2FD', icon: <HourglassEmptyOutlined /> }
};