import React, { useEffect } from 'react';
import CustomMuiDialogWrapper from '../CommonComponents/CustomMuiDialogWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { resetIntegrationDocumentSelectionModal } from '../redux/slices/integrationsSlice';
import UploadMedicals from './UploadMedicals';
import ConfirmDeleteFile from './ConfirmDeleteFile';
import ReimportMedicals from './ReimportMedicals';
import SavingDemand from './SavingDemand';
import AddICDCode from './AddICDCode';
import EditICDCode from './EditICDCode';
import AddMriFinding from './AddMriFinding';
import EditMRIFinding from './EditMRIFinding';
import DocumentEditingConfirmation from './DocumentEditingConfirmation';
import EditNotesDialog from './EditNotesDialog';
import AddMedicalTreatment from './AddMedicalTreatment';
import SendForAIProcessingConfirmation from './SendForAIProcessingConfirmation';
import UploadDocumentsDialog from './UploadDocumentsDialog';
import IntegrationSearchModal from '../DemandComponents/DemandForms/Integrations/IntegrationSearchModal';
import DocumentSelectionModal from '../DemandComponents/DemandForms/Integrations/DocumentSelectionModal';
import BulkEditConfirmation from './BulkEditConfirmation';
import ReviewDocumentsDialog from '../dialogs/ReviewDocumentsDialog';
import { useConfirmationDialog } from '../dialogs/contexts/DialogContext';
import ConfirmationDialog from './ConfirmationDialog';

const GlobalDialogues = () => {

  const dispatch = useDispatch();

  const {
    uploadMedicals,
    confirmDeleteFile,
    reimportMedicals,
    savingDemand,
    addICDCode,
    editICDCode,
    addMriFinding,
    editMRIFinding,
    documentEditingConfirmation,
    editDemandNotes,
    addMedicalTreatment,
    sendForAIProcessingConfirmation,
    uploadDocumentsDialog,
    integrationSearch,
    confirmationDialog
  } = useSelector((state) => state.GlobalDialogues);

  const { integrationDocumentSelectionModal } = useSelector((state) => state.Integrations);

  const { bulkEditConfirmation, reviewDocumentsConfirmation } = useConfirmationDialog();

  const handleCloseDialog = () => dispatch(resetGlobalDialogues());

  const handleCloseAllDialogs = () => {
    dispatch(resetGlobalDialogues());
    dispatch(resetIntegrationDocumentSelectionModal());
  };

  useEffect(() => {
    window.addEventListener('popstate', handleCloseAllDialogs);
    return () => window.removeEventListener('popstate', handleCloseAllDialogs);
  }, []);

  return (
    <>
      {/* todo: map all of these (to avoid redundancy) and add wrapper, requires removing <Dialog> element from the display component */}
      {/* {maplist will need to include any custom props that are passed to the dialogues} */}
      {documentEditingConfirmation.open && <DocumentEditingConfirmation />}
      {uploadMedicals.open && <UploadMedicals />}
      {confirmDeleteFile.open && <ConfirmDeleteFile />}
      {reimportMedicals.open && <ReimportMedicals />}
      {savingDemand.open && <SavingDemand />}
      {addICDCode.open && <AddICDCode />}
      {editICDCode.open && <EditICDCode />}
      {editMRIFinding.open && <EditMRIFinding />}
      {editDemandNotes.open && <EditNotesDialog />}
      {sendForAIProcessingConfirmation.open && <SendForAIProcessingConfirmation />}

      <CustomMuiDialogWrapper
        open={addMedicalTreatment.open}
        onClose={handleCloseDialog}
        fullWidth={true}
      >
        <AddMedicalTreatment />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={uploadDocumentsDialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth='md'
      >
        <UploadDocumentsDialog />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={addMriFinding.open}
        onClose={handleCloseDialog}
        fullWidth={true}
      >
        <AddMriFinding />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={integrationSearch.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth='md'
        sx={{ zIndex: 1350 }} //z-index between compose demand modal and toast
      >
        <IntegrationSearchModal />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={integrationDocumentSelectionModal.open}
        onClose={() => dispatch(resetIntegrationDocumentSelectionModal())}
        fullWidth={true}
        maxWidth="md"
      >
        <DocumentSelectionModal />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={bulkEditConfirmation.open}
        fullWidth={true}
      >
        <BulkEditConfirmation />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={reviewDocumentsConfirmation.open}
        fullWidth={true}
      >
        <ReviewDocumentsDialog />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={confirmationDialog.open}
        fullWidth={true}
      >
        <ConfirmationDialog />
      </CustomMuiDialogWrapper>

    </>
  );
};

export default GlobalDialogues;
