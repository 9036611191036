// React and React Router
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Material UI Components
import Container from '@mui/material/Container';
import { Stack, ThemeProvider, Button, Alert, Snackbar, Tooltip, Menu, Typography, IconButton, Grid, Box, ListItemText, ListItemIcon, Divider, MenuItem, Badge, Paper } from '@mui/material';
// Material UI Icons
import { ArrowBack, ErrorOutline, MoreHorizOutlined, PictureAsPdfOutlined, RemoveRedEyeOutlined, SendRounded, TextSnippetOutlined, ArchiveOutlined, EditNoteSharp, AutoAwesome, Done } from '@mui/icons-material';
// Theme and Utilities
import { theme } from '../Theme';
import { isRequestApprovalEnabled, userHasPermission, userHasRole, isDocumentFileUploading } from '../common';
import { buildProviderSummary } from '../common-medicals';
import {
  DOCUMENT_UPLOADED,
  AWAITING_MEDICAL_EXTRACTION,
  AWAITNG_PRECEDENT_SIGNOFF,
  WAITING_FIRM_APPROVAL,
  REJECTED_BY_LAW_FIRM,
  HANDLING_COMPLETED
} from '../common-demand';
import { isDemandS3UrlExpired, documentFilesHaveUserUploadedFile, DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT } from '../common-document';
import { LAW_FIRM_APPROVER_ROLE, LAW_FIRM_ADMIN_ROLE } from '../common-roles';
// Components
import MedicalsProviderSummary from '../MedicalsComponents/MedicalsProviderSummary';
import MedicalsOverview from '../MedicalsComponents/MedicalsOverview';
import MedicalsTreatmentsChart from '../eCharts/MedicalsTreatmentsChart';
import MedicalsOverviewPrecedent from '../MedicalsComponents/MedicalsOverviewPrecedent';
import MedicalsTreatments from '../MedicalsComponents/MedicalsTreatments';
import CollapseCard from '../CommonComponents/CollapseCard';
import EditMenu from '../DemandComponents/EditMenu';
import StatusChip from '../CommonComponents/Chips/StatusChip';
import UploadDocumentsMenuItem from '../DemandComponents/UploadDocumentsMenuItem';
import DemandWorkflowTooltip from '../DemandComponents/DemandWorkflowTooltip';
import OverrideReview from '../GlobalDialogues/OverrideReview';
// Dialogs
import FirmRejectDialog from '../dialogs/FirmRejectDialog';
import FirmApproveDialog from '../dialogs/FirmApproveDialog';
import SendPreviewDialog from '../dialogs/SendPreviewDialog';
import AttorneySendDialog from '../dialogs/AttorneySendDialog';
import ArchiveDialog from '../dialogs/ArchiveDialog';
import StatusDialog from '../dialogs/StatusDialog';
// API and Redux
import { rejectDemandApi, archiveApi, getConfigValue } from '../api';
import { useGetAllFilesByDocumentIdQuery } from "../services/documentFiles/documentFilesApi";
import { fetchTemplateData, fetchTemplateSpecificVersionData } from '../redux/thunks/templateData';
import { fetchCustomerData } from '../redux/thunks/customerData';
import { useGetPdfUrlQuery } from '../services/pdf/pdfApi';
import { useGetDocumentDataQuery, useLazyGetDocumentDataQuery } from '../services/documentData/documentDataApi';
// Redux Actions
import { setEditAllDocumentsOpen, setEditDemandDetailsOpen, setOverrideReview } from '../redux/slices/demandDomSlice';
import { clearDocumentState, setUsesDividerPages } from '../redux/slices/documentSlice';
import { clearMedicalsState } from '../redux/slices/medicalsSlice';
import { setAttorneyOverrideReview, setAttorneySend, setEditDemandNotes, setSendForAIProcessingConfirmation } from '../redux/slices/globalDialogueSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';

// Helpers
import { canComposeDemand, isCompositeDemand, hasNotAllowedStatus } from '../DemandComponents/helpers';
import { parseQueryStringToObject } from '../DemandComponents/DemandForms/Integrations/helpers';
import { resetIntegrationState } from '../redux/slices/integrationsSlice';
import UploadDemandToIntegration from '../DemandComponents/Menus/MenuItems/UploadDemandToIntegration';
import DemandBreadCrumb from '../DemandComponents/Navigation/DemandBreadCrumb';
import StrengthOfCaseTags from '../DemandComponents/StrrengthOfCaseTags';
import CustomerSpecialNotes from '../DemandComponents/CustomerSpecialNotes';
import DetailsAndInsights from '../DemandComponents/DetailsAndInsights';

export const MedicalsLawFirmViewer = () => {
  const { documentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: allDocumentData = {} } = useGetDocumentDataQuery({ documentId, pollPdfStatus: false }, { skip: !documentId });
  const { documentData, documentMetaData } = useSelector((state) => state.Document);
  const { customerId } = documentData || {};
  const documentStatus = documentMetaData?.documentStatus;

  const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
    refetchOnMountOrArgChange: true,
  });

  const [triggerGetDocumentData] = useLazyGetDocumentDataQuery();

  const { data: { demandS3Url } = {} } = useGetPdfUrlQuery(documentId, { skip: !documentId, refetchOnReconnect: true });

  const { demandS3RegenerationSkeletonData, demandS3RegenerationLoadingData } = useSelector((state) => state.PdfCustomSlice);
  const isPdfRegenLoading = Boolean(demandS3RegenerationLoadingData[documentId]) || (Boolean(demandS3RegenerationSkeletonData[documentId]));

  const { usesDividerPages, isAiProcessingForCreateDocument } = useSelector((state) => state.Document);
  const { user, userData } = useSelector((state) => state.User);
  const { medicals } = useSelector((state) => state.Medicals);

  const [providerSummary, setProviderSummary] = useState([]);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastSeverity, setToastSeverity] = useState('success');
  const [toastTimeout, setToastTimeout] = useState(2000);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [useDemandNotesEnabled, setUseDemandNotesEnabled] = useState(false);

  //placeholder array of tags, will need an API call to retrieve these
  const tags = ["Positive MRI findings", "Charges exceed demand amount", "Ambulance"]


  const menuopen = Boolean(anchorEl);

  const setNotes = async () => setUseDemandNotesEnabled((await getConfigValue('useDemandNotesEnabled', null, user)));

  useEffect(() => {
    setNotes();
    dispatch(fetchDocumentData({ documentId, user }));
    getUseDividerPagesEnabled(customerId);

    // Handle redirect back from integration auth
    const queryParams = parseQueryStringToObject(window.location.search);
    if (queryParams.authSuccess && queryParams.integrationState.form === 'edit') {
      dispatch(setEditDemandDetailsOpen(true));
    }

    return () => {
      dispatch(clearDocumentState());
      dispatch(clearMedicalsState());
      dispatch(resetIntegrationState());
    };
  }, []);

  const [carrierEmailAddress, setCarrierEmailAddress] = useState();
  const [carrierFaxNumber, setCarrierFaxNumber] = useState();

  const noDemandResponseDueDate =
    !documentData?.demandDetails?.demandResponseDueDate ||
    documentData?.demandDetails?.demandResponseDueDate === 'Invalid date';

  const handleRejectDialogClose = () => setRejectDialogOpen(false);
  const handleApproveDemand = () => setApproveDialogOpen(true);
  const handleSendForApproval = () => {
    dispatch(setOverrideReview(false));
    if (userHasRole(LAW_FIRM_APPROVER_ROLE, userData)) {
      dispatch(setAttorneyOverrideReview({ open: true }))
    } else {
      dispatch(setAttorneySend({ open: true }))
    }
  };

  const handleCompleteHandling = () => {
    dispatch(setAttorneySend({ open: true }))
  };

  const handleApproveDialogClose = () => setApproveDialogOpen(false);
  const handleRejectDemand = () => setRejectDialogOpen(true);

  const handleToastClose = () => {
    setToastMessage('');
    setToastOpen(false);
  };

  const handlePreviewEmail = () => {
    setPreviewDialogOpen(true);
    handleMenuClose();
  };
  const handlePreviewDialogClose = () => setPreviewDialogOpen(false);
  const handleStatusDialogClose = () => setStatusDialogOpen(false);

  const handleSendForAiProcessing = () => {
    dispatch(setSendForAIProcessingConfirmation({
      open: true,
      data: {
        documentId,
      }
    }));
  };

  useEffect(() => {
    if (user && documentMetaData) {
      dispatch(fetchTemplateData({ templateId: documentMetaData?.demandTemplateId, user }));
      dispatch(
        fetchTemplateSpecificVersionData({
          templateId: documentMetaData?.demandTemplateId,
          version: documentMetaData?.demandTemplatePinnedVersion,
          user,
        })
      );
    }
  }, [user, documentMetaData]);

  useEffect(() => {
    if (customerId && user) {
      dispatch(fetchCustomerData({ customerId, user }));
    }
  }, [customerId, user]);

  const getUseDividerPagesEnabled = async (customerId) => {
    const useDividerPagesEnabled = await getConfigValue("useDividerPagesEnabled", customerId, user) || false;
    dispatch(setUsesDividerPages(useDividerPagesEnabled));
  };

  const handleSendComplete = (success) => {
    success && triggerGetDocumentData({ documentId, pollPdfStatus: true });
  };

  const handleArchiveDemand = (event) => {
    handleMenuClose(event);
    setArchiveDialogOpen(true);
  };

  const handleArchiveDialogClose = () => {
    setArchiveDialogOpen(false);
  };

  const showStatusDialog = (text, isCloseDisabled = false) => {
    setStatusDialogText(text);
    setStatusDialogOpen(true);
    setStatusCloseDisabled(isCloseDisabled);
  };

  const handleBackNavClick = () => (location.state?.from ? navigate(location.state.from) : navigate('/inventory'));
  const handleMenuClose = () => setAnchorEl(null);

  const rejectDemand = (reason, comment) => {
    setRejectDialogOpen(false);
    showStatusDialog('Sending notification...');

    rejectDemandApi(documentId, { rejectReason: reason, comment: comment }, user).then((response) => {
      if (response.status == 200) {
        showStatusDialog('The notification has been delivered');
        triggerGetDocumentData({ documentId, pollPdfStatus: true });
      } else {
        showStatusDialog('There was an error delivering the rejection. Please contact support.');
      }
    });
  };

  const archiveDemand = async (archiveReason, archiveComments) => {
    setArchiveDialogOpen(false);
    const archiveData = {
      archiveReason: archiveReason,
      archiveComments: archiveComments,
    };

    archiveApi(documentId, archiveData, user).then((response) => {
      if (response.status == 200) {
        showStatusDialog('Success! The demand has been archived.');
        triggerGetDocumentData({ documentId, pollPdfStatus: false });
      } else {
        showStatusDialog('There was an error archiving the demand.');
      }
    });
  };

  const sendFirmApprovalRequestComplete = (success) => success && triggerGetDocumentData({ documentId, pollPdfStatus: true });

  const sendPreviewComplete = () => null;

  useEffect(() => {
    medicals?.medicalTreatments && setProviderSummary(buildProviderSummary(medicals.medicalTreatments));
  }, [medicals]);

  const isEditDocumentsDisabled =
    isDemandS3UrlExpired(demandS3Url) ||
    (userHasPermission('DemandCompose', userData) &&
      [AWAITING_MEDICAL_EXTRACTION, AWAITNG_PRECEDENT_SIGNOFF, REJECTED_BY_LAW_FIRM].includes(documentStatus));

  const isApprovalButtonVisible =
    isCompositeDemand(documentMetaData?.documentType) &&
    (userHasPermission('DemandApproval', userData) || userHasPermission('DemandRequestApproval', userData)) &&
    !(
      (userHasRole(LAW_FIRM_ADMIN_ROLE, userData) || userHasRole(LAW_FIRM_APPROVER_ROLE, userData)) &&
      documentStatus === WAITING_FIRM_APPROVAL
    ) &&
    !(documentStatus === HANDLING_COMPLETED);

  const isUploadDocumentsVisible =
    isCompositeDemand(documentMetaData?.documentType) && userHasPermission('DemandCompose', userData);

  const isDocumentSubmittedOrReceived = documentStatus === 'DocumentSubmitted' || documentStatus === 'DocumentReceived' || documentStatus === 'HandlingCompleted';

  const compositeDemandOrSentToCarrier = isCompositeDemand(documentMetaData?.documentType) ||
    documentStatus === 'DocumentSubmitted' ||
    documentStatus === 'DocumentReceived'

  const isNoTemplateDemand = documentMetaData.demandTemplateId === ""

  const additionalDocumentEditsDisabled = documentStatus === 'DocumentApproved' || documentStatus === 'DocumentSubmitted' || documentStatus === 'DocumentReceived' || documentStatus === 'HandlingCompleted' || documentStatus === 'DocumentArchived';

  const hasUserUploadedFile = documentFilesHaveUserUploadedFile(documentFiles);

  const isFileUploading = isDocumentFileUploading(documentFiles);

  return (
    <Container maxWidth="100" sx={{ padding: '10px' }}>
      <ThemeProvider theme={theme}>
        {documentData && userData ? (
          <>
            <DemandBreadCrumb
              label={documentData?.sendingFirm.firmCaseNumber}
              statusChip={compositeDemandOrSentToCarrier ? (
                <Tooltip
                  title={
                    documentStatus !== 'DocumentArchived' && (
                      <DemandWorkflowTooltip documentStatus={isAiProcessingForCreateDocument ? 'AwaitingMedicalExtraction' : documentStatus} demandIsDeliverable={documentMetaData?.demandIsDeliverable} />
                    )
                  }
                  placement="right-start"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-6, -16],
                          },
                        },
                      ],
                    },
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontWeight: 450,
                        bgcolor: 'transparent',
                      },
                    },
                  }}
                >
                  <div>
                    <StatusChip
                      type="document"
                      documentStatus={isAiProcessingForCreateDocument ? 'AwaitingMedicalExtraction' : documentStatus}
                      showInfoIcon={documentStatus !== 'DocumentArchived'}
                      showEditOffIcon={false}
                      demandIsDeliverable={documentMetaData?.demandIsDeliverable}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="This demand cannot be edited" placement="bottom-start">
                  <span>
                    <StatusChip
                      type="document"
                      documentStatus={documentStatus}
                      showInfoIcon={!isDocumentSubmittedOrReceived}
                      showEditOffIcon={!isDocumentSubmittedOrReceived}
                      demandIsDeliverable={documentMetaData?.demandIsDeliverable}
                    />
                  </span>
                </Tooltip>
              )}

              callsToAction={
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems="center"
                >
                  {/* begin overflow menu */}
                  {/* actions menu for md breakpoints and up */}
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    endIcon={<MoreHorizOutlined />}
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                  >
                    Actions
                  </Button>

                  {/* actions menu for xs and sm breakpoints */}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    endIcon={<MoreHorizOutlined />}
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                  >
                    Actions
                  </Button>

                  {useDemandNotesEnabled || !additionalDocumentEditsDisabled ?
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                      <EditMenu
                        user={user}
                        userData={userData}
                        showTooltip={hasNotAllowedStatus(documentMetaData)}
                        documentId={documentId}
                        useDemandNotesEnabled={useDemandNotesEnabled}
                        currentEditNote={documentData?.customerSpecialNotes ?? ''}
                        isEditDocumentsDisabled={isEditDocumentsDisabled}
                        editDocumentsToolTipText={DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT}
                      />
                    </Box>
                    :
                    ''
                  }

                  {documentStatus === DOCUMENT_UPLOADED && !isAiProcessingForCreateDocument ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<AutoAwesome />}
                      onClick={() => handleSendForAiProcessing()}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      disabled={isPdfRegenLoading || !hasUserUploadedFile || isFileUploading}
                    >
                      Send for AI processing
                    </Button>
                  ) : documentStatus === 'DocumentApproved' && !documentMetaData.demandIsDeliverable ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleCompleteHandling()}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                      Complete handling
                    </Button>
                  ) : (
                    isApprovalButtonVisible && (
                      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip
                          title={
                            noDemandResponseDueDate &&
                            documentStatus === 'AwaitingFirmReview' &&
                            'Add a response due date in edit demand details'
                          }
                          placement="bottom-end"
                        >
                          <Badge
                            badgeContent={'!'}
                            color="warning"
                            invisible={!noDemandResponseDueDate || documentStatus !== 'AwaitingFirmReview'}
                          >
                            <span>
                              {!additionalDocumentEditsDisabled &&
                                <Button
                                  disabled={isAiProcessingForCreateDocument ||
                                    !isRequestApprovalEnabled(documentStatus, documentMetaData?.documentType, userData)
                                  }
                                  onClick={() => handleSendForApproval()}
                                  variant="contained"
                                  color="secondary"
                                  startIcon={<SendRounded />}
                                >
                                  {documentStatus === 'DocumentApproved' && !documentMetaData.demandIsDeliverable ? 'Complete handling' : 'Send for approval'}
                                </Button>
                              }

                            </span>
                          </Badge>
                        </Tooltip>
                      </Box>
                    )
                  )}

                  {/* actions menu for md breakpoints and up */}

                  {userHasPermission('DemandApproval', userData) && documentStatus === 'WaitingFirmApproval' && (
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                      <Tooltip
                        placement="top"
                        disableHoverListener={
                          !(
                            !documentData?.recipientCarrier.contactInfo.emailAddress &&
                            !documentData?.recipientCarrier.contactInfo.faxNumber
                          )
                        }
                        arrow
                      >
                        <span>
                          <Button
                            disabled={documentStatus != 'WaitingFirmApproval'}
                            onClick={(e) => {
                              handleApproveDemand();
                            }}
                            variant="contained"
                            color="secondary"
                            startIcon={<SendRounded />}
                          >
                            {documentMetaData?.demandIsDeliverable ? 'Approve and send to carrier' : 'Approve demand'}
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                  )}

                  {/* begin overflow menu */}

                  <Menu
                    id="menu-options"
                    keepMounted
                    anchorEl={anchorEl}
                    open={menuopen}
                    disableScrollLock
                    onClose={(e) => {
                      handleMenuClose(e);
                    }}
                  >
                    {documentStatus === 'DocumentApproved' && !documentMetaData.demandIsDeliverable ?


                      <Stack
                        spacing={1}
                        sx={{
                          display:
                          {
                            xs: 'flex', md: 'none'
                          }
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleMenuClose();
                            handleCompleteHandling()
                          }}
                          sx={{
                            width: '100%'
                          }}
                        >
                          <ListItemIcon>
                            <Done />
                          </ListItemIcon>
                          <ListItemText primary="Complete handling" />
                        </MenuItem>
                        <Divider sx={{ display: { xs: 'flex', md: 'none' } }} />
                      </Stack>


                      :
                      ''
                    }

                    {/* show primary cta in menu below md breakpoints */}
                    {documentStatus === DOCUMENT_UPLOADED && !isAiProcessingForCreateDocument ? (
                      <MenuItem
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                        onClick={() => handleSendForAiProcessing()}
                        disabled={isPdfRegenLoading || !hasUserUploadedFile}
                      >
                        <ListItemIcon>
                          <AutoAwesome />
                        </ListItemIcon>
                        <ListItemText primary="Send for AI processing" />
                      </MenuItem>
                    ) : (
                      isApprovalButtonVisible && [
                        // Use an array because MUI complains if a fragment is used as a child of Menu
                        !additionalDocumentEditsDisabled &&
                        <Box>
                          <Box key="send-for-approval" sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <Tooltip
                              title={
                                noDemandResponseDueDate &&
                                documentStatus === 'AwaitingFirmReview' &&
                                'Add a response due date in edit demand details'
                              }
                              placement="bottom-end"
                            >
                              <span style={{ width: '100%' }}>
                                <Stack direction="row" alignItems={'center'} sx={{ width: '100%' }}>
                                  <MenuItem
                                    disabled={
                                      !isRequestApprovalEnabled(
                                        documentStatus,
                                        documentMetaData?.documentType,
                                        userData
                                      )
                                    }
                                    onClick={() => handleSendForApproval()}
                                    sx={{ width: noDemandResponseDueDate ? 'auto' : '100%' }}
                                  >
                                    <ListItemIcon>
                                      <SendRounded />
                                    </ListItemIcon>
                                    <ListItemText primary="Send for approval" />
                                  </MenuItem>
                                  <Badge
                                    badgeContent={'!'}
                                    color="warning"
                                    invisible={!noDemandResponseDueDate || documentStatus !== 'AwaitingFirmReview'}
                                  ></Badge>
                                </Stack>
                              </span>
                            </Tooltip>
                          </Box>
                          <Divider
                            key="send-for-approval-divider"
                            sx={{ my: 1, display: { xs: 'flex', md: 'none' } }}
                          />
                        </Box>
                      ]
                    )}
                    {userHasPermission('DemandApproval', userData) && documentStatus !== 'AwaitingFirmReview' && (
                      !additionalDocumentEditsDisabled &&
                      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip
                          placement="top"
                          disableHoverListener={
                            !(
                              !documentData?.recipientCarrier.contactInfo.emailAddress &&
                              !documentData?.recipientCarrier.contactInfo.faxNumber
                            )
                          }
                          arrow
                        >
                          <Box
                            sx={{
                              width: '100%'
                            }}
                          >
                            <MenuItem
                              disabled={documentStatus != 'WaitingFirmApproval'}
                              onClick={(e) => {
                                handleApproveDemand();
                              }}
                            >
                              <ListItemIcon>
                                <SendRounded />
                              </ListItemIcon>
                              <ListItemText primary={documentMetaData?.demandIsDeliverable ? 'Approve and send to carrier' : 'Approve demand'} />
                            </MenuItem>
                            <Divider sx={{ display: { xs: 'flex', md: 'none' } }} />
                          </Box>
                        </Tooltip>
                      </Box>
                    )}

                    {/* show edit actions in actions menu below md breakpoints */}

                    {(userHasPermission('DemandApproval', userData) ||
                      userHasPermission('DemandCompose', userData) ||
                      userHasPermission('DemandEdit', userData)) && (
                        <div>
                          {canComposeDemand(userData, documentMetaData) && (
                            <>
                              <MenuItem
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  dispatch(setEditDemandDetailsOpen(true));
                                }}
                              >
                                <ListItemIcon>
                                  <TextSnippetOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Edit demand details" />
                              </MenuItem>

                              <Tooltip
                                title={isEditDocumentsDisabled ? DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT : ''}
                                arrow
                                placement="left"
                              >
                                <span>
                                  <MenuItem
                                    sx={{ display: { xs: 'flex', md: 'none' } }}
                                    onClick={() => {
                                      setAnchorEl(null);
                                      dispatch(setEditAllDocumentsOpen(true));
                                    }}
                                    disabled={isEditDocumentsDisabled}
                                  >
                                    <ListItemIcon>
                                      <PictureAsPdfOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit documents" />
                                  </MenuItem>
                                </span>
                              </Tooltip>
                            </>
                          )}

                          {useDemandNotesEnabled && (
                            <MenuItem
                              sx={{ display: { xs: 'flex', md: 'none' } }}
                              onClick={() => {
                                setAnchorEl(false);
                                dispatch(
                                  setEditDemandNotes({
                                    open: true,
                                    isOpenFromInventory: false,
                                    data: { documentId, user },
                                  })
                                );
                              }}
                              disabled={!userHasPermission('DemandCompose', userData)}
                            >
                              <ListItemIcon>
                                <EditNoteSharp />
                              </ListItemIcon>
                              <ListItemText primary="Edit notes" />
                            </MenuItem>
                          )}

                          {(canComposeDemand(userData, documentMetaData) || useDemandNotesEnabled) && (
                            <Divider sx={{ display: { xs: 'flex', md: 'none' } }} />
                          )}

                          {userHasPermission('DemandApproval', userData) && (
                            <>
                              {documentMetaData?.documentStatus === 'WaitingFirmApproval' ? (
                                <MenuItem
                                  onClick={(e) => {
                                    handleMenuClose();
                                    handleRejectDemand();
                                  }}
                                >
                                  <ListItemIcon>
                                    <ErrorOutline />
                                  </ListItemIcon>
                                  <ListItemText primary="Request additional review" />
                                </MenuItem>
                              ) : (
                                additionalDocumentEditsDisabled ? "" :
                                  <Tooltip
                                    title="Demand is currently under review"
                                    arrow
                                    placement='left'
                                  >
                                    <div>
                                      <MenuItem disabled={true}>
                                        <ListItemIcon>
                                          <ErrorOutline />
                                        </ListItemIcon>
                                        <ListItemText primary="Request additional review" />
                                      </MenuItem>
                                    </div>
                                  </Tooltip>


                              )}
                            </>
                          )}
                        </div>
                      )}
                    {isUploadDocumentsVisible && !additionalDocumentEditsDisabled && (
                      <UploadDocumentsMenuItem handleMenuClose={handleMenuClose} />
                    )}

                    <UploadDemandToIntegration
                      documentId={documentId}
                      documentStatus={documentStatus}
                      handleMenuClose={handleMenuClose}
                    />

                    <MenuItem
                      onClick={() => {
                        handlePreviewEmail();
                      }}
                    >
                      <ListItemIcon>
                        <RemoveRedEyeOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Email preview to me" />
                    </MenuItem>

                    {userHasPermission('DemandArchive', userData) && documentStatus != 'DocumentArchived' ? (
                      <div>
                        <MenuItem
                          onClick={(e) => {
                            handleArchiveDemand(e);
                          }}
                        >
                          <ListItemIcon>
                            <ArchiveOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Archive demand" />
                        </MenuItem>
                      </div>
                    ) : null}
                  </Menu>
                </Stack>
              }

            />



            <Stack direction="column" spacing={2} sx={{ marginTop: '130px' }}>
              {/* chips */}
              <StrengthOfCaseTags tags={tags} />

              {/* notes */}
              <CustomerSpecialNotes />

              {/* details and insights */}
              <DetailsAndInsights
                providerSummary={providerSummary}
              />
              
              <MedicalsProviderSummary providerSummary={providerSummary} />
              {medicals?.medicalTreatments && medicals?.medicalTreatments?.length > 0 && (
                <MedicalsTreatmentsChart documentData={documentData} />
              )}
              <MedicalsTreatments
                viewType="lawfirm"
                documentId={documentId}
                medicals={medicals}
                user={user}
              />
            </Stack>
          </>
        ) : (
          <p></p>
        )}

        <FirmRejectDialog
          handler={rejectDemand}
          handleClose={handleRejectDialogClose}
          dialogOpen={rejectDialogOpen}
        ></FirmRejectDialog>

        <SendPreviewDialog
          user={user}
          documentId={documentId}
          handler={sendPreviewComplete}
          handleClose={handlePreviewDialogClose}
          dialogOpen={previewDialogOpen}
        ></SendPreviewDialog>

        <StatusDialog
          closeDisabled={statusCloseDisabled}
          handleClose={handleStatusDialogClose}
          dialogOpen={statusDialogOpen}
          dialogText={statusDialogText}
        ></StatusDialog>

        <AttorneySendDialog
          viewType="precedent"
          user={user}
          userData={userData}
          documentId={documentId}
          deliveryEmail={`${documentData?.sendingFirm?.attorney?.firstName} ${documentData?.sendingFirm?.attorney?.lastName}`}
          handler={handleSendComplete}
        ></AttorneySendDialog>

        <OverrideReview
          carrierCommonName={documentData?.recipientCarrier?.carrierCommonName}
        />

        {archiveDialogOpen && (
          <ArchiveDialog
            firmCaseNumber={documentData?.sendingFirm.firmCaseNumber}
            handler={archiveDemand}
            handleClose={handleArchiveDialogClose}
            dialogOpen={archiveDialogOpen}
          ></ArchiveDialog>
        )}

        {documentData && (
          <FirmApproveDialog
            user={user}
            handler={sendFirmApprovalRequestComplete}
            documentId={documentId}
            carrierEmailAddress={carrierEmailAddress}
            carrierFaxNumber={carrierFaxNumber}
            viewType="lawfirm"
            handleClose={handleApproveDialogClose}
            dialogOpen={approveDialogOpen}
            docData={documentData}
          ></FirmApproveDialog>
        )}

        <Snackbar
          open={toastOpen}
          autoHideDuration={toastTimeout}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Paper elevation={8}>
            <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
              {toastMessage}
            </Alert>
          </Paper>
        </Snackbar>
      </ThemeProvider>
    </Container>
  );
};
