import React, { useMemo, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Chip, Stack, Typography, Tooltip, CircularProgress, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterListDictionary, filterDispatchDictionary } from './quickFiltersConfigs';
import { grey } from '@mui/material/colors';
import AttorneyFilter from './AttorneyFilter';

const QuickFilters = ({ filterName, data = [], statusCounts = [], rowsInGrid, totalDocuments, loading, handleClick, startIndex, endIndex, visibleRows = {}, showCustomFilter = false }) => {
    const visibleRowCount = Object.values(visibleRows).filter(val => val).length;
    const dispatch = useDispatch();
    const updateFilterState = filterDispatchDictionary[filterName].updateFilterState;
    const updateFilteredContacts = filterDispatchDictionary[filterName].updateFilteredContacts;
    const updateFilteredActiveDocuments = filterDispatchDictionary[filterName].updateFilteredActiveDocuments;
    const reduxState = useSelector(state => state[filterName])
    const filterState = reduxState.filterState;
    const filterListDictionary = useMemo(() => getFilterListDictionary(data), [data]);
    const filterInfo = useMemo(() => filterListDictionary[filterName], [filterListDictionary, filterName]);
    const isFirmFilterView = filterName === 'Inventory' && startIndex === 0;
    const currentFilter = filterState.quickFilters.find(filter => filter.toggleState);
    const currentFilterName = currentFilter.label ? currentFilter.label : currentFilter.name;
    const filteredActiveDocuments = filterState.filteredActiveDocuments;
    const currentQuickFilterData = filterInfo[currentFilterName].filteredData;


    const handleFilterChipClick = (filterName, isSelected, filteredActiveDocuments = null) => {
        handleClick && handleClick();
        if (isSelected) return;

        const newQuickFilterState = filterState.quickFilters.map((filter, i) => {
            return {
                ...filter,
                toggleState: filter.name === filterName ? true : false,
            }
        })

        dispatch(updateFilterState({ ...filterState, quickFilters: newQuickFilterState, filteredActiveDocuments }))

    }

    const handleChange = (event) => {
        const newFilterName = event.target.value;
        const filteredActiveDocuments = null;
        handleFilterChipClick(newFilterName, false, filteredActiveDocuments);
    };

    useEffect(() => {
        if (currentQuickFilterData) {
            dispatch(updateFilterState({ ...filterState, filteredActiveDocuments: currentQuickFilterData }))
        }
    }, [currentQuickFilterData])

    const displayedQuickFilters = startIndex !== null && endIndex !== null ? filterState.quickFilters.slice(startIndex, endIndex) : filterState.quickFilters;

    return (
        <Grid alignItems='flex-start' container spacing={1}>
            {displayedQuickFilters.map((filter, idx) => {
                const getRowCount = filterInfo[filter.name].getRowCount(data, statusCounts, totalDocuments, filter);
                const { rowCount } = getRowCount;
                const tooltipText = filterListDictionary[filterName][filter.name].tooltipText;
                const isSelected = filter.toggleState;

                var countLabel = ""
                if (rowCount != rowsInGrid) {
                    countLabel = `${rowsInGrid} / ${rowCount}`;
                }
                else {
                    countLabel = rowCount;
                }

                return (
                    <Box
                        direction={'row'}
                        spacing={1}
                    >
                        <Chip
                            key={idx}
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                borderRadius: '100px',
                                ml: 1,
                                mt: 1,
                                height: 40,
                                backgroundColor: isSelected ? grey[300] : '#ffffff',
                                border: isSelected ? '1px solid transparent' : '1px solid rgba(0,0,0,0.12)', '&:hover': { backgroundColor: isSelected ? grey[300] : grey[200] }
                            }}
                            label={<>
                                {isSelected &&
                                    <Stack direction="row" alignItems='center' spacing={2}>
                                        <Stack direction="row" alignItems={'center'} spacing={1}>
                                            {loading ? <CircularProgress size={14} /> :
                                                <Typography variant="body1" color='primary'>
                                                    {loading ? <CircularProgress size={14} /> : countLabel}
                                                </Typography>
                                            }
                                            <Typography variant="body1" sx={{ color: grey[600] }}>
                                                |
                                            </Typography>
                                            <Typography variant="body2" sx={{ lineHeight: '14px' }} color='primary'>
                                                {filter.label ? filter.label : filter.name}
                                            </Typography>
                                        </Stack>
                                        <Tooltip title={tooltipText}>
                                            <InfoIcon fontSize="small" sx={{ color: 'primary', display: tooltipText ? 'block' : 'none' }} />
                                        </Tooltip>
                                    </Stack>
                                }
                                {!isSelected &&
                                    <Stack direction="row" alignItems='center' spacing={2}>
                                        <Stack direction="row" alignItems={'center'} spacing={1}>
                                            {loading ? <CircularProgress size={14} /> :
                                                <Typography variant="body1" sx={{ color: 'primary' }}>
                                                    {loading ? <CircularProgress size={14} /> : rowCount}
                                                </Typography>
                                            }
                                            <Typography variant="body1" sx={{ color: grey[400] }}>
                                                |
                                            </Typography>

                                            <Typography variant="body2" sx={{ lineHeight: '14px' }}>
                                                {filter.label ? filter.label : filter.name}
                                            </Typography>
                                        </Stack>
                                        <Tooltip title={tooltipText}>
                                            <InfoIcon fontSize="small" sx={{ color: 'rgba(0,0,0,0.54)', display: tooltipText ? 'block' : 'none' }} />
                                        </Tooltip>
                                    </Stack>
                                }
                            </>
                            }
                            clickable
                            onClick={e => handleFilterChipClick(filter.name, isSelected, null)}
                        />
                        {idx === 0 &&
                            <FormControl fullWidth sx={{ mt: 1, ml: 1, display: { xs: 'flex', md: 'none' } }}>
                                <InputLabel id="quick-filter-select-label">Quick filter</InputLabel>
                                <Select
                                    labelId="quick-filter-select-label"
                                    id="quick-filter-select"
                                    value={currentFilterName}
                                    label="Quick filter"
                                    onChange={handleChange}
                                    sx={{
                                        width: 250
                                    }}
                                >
                                    {displayedQuickFilters.map((filter, idx) => (
                                        <MenuItem key={idx} value={filter.name}>
                                            {filter.label ? filter.label : filter.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        }

                    </Box>
                )
            })}

            {isFirmFilterView && showCustomFilter &&
                <Stack direction="row" alignItems='center' sx={{ ml: .5, mt: 1 }}>
                    <AttorneyFilter data={data} updateFilteredActiveDocuments={updateFilteredActiveDocuments} currentQuickFilterData={currentQuickFilterData} updateFilteredContacts={updateFilteredContacts} />
                </Stack>}
        </Grid>
    );
}

export default QuickFilters;