import { apiSlice } from '../apiSlice';

const demandScoreApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        // /document/{documentId}/generateDemandScore/{version}
        generateDemandScore: builder.query({
            query: ({ documentId, version }) => ({
                url: `/document/${documentId}/generateDemandScore/${version}`,
                method: 'GET',
            }),
        }),

    }),
});

export const {
    useGenerateDemandScoreQuery,
    useLazyGenerateDemandScoreQuery
} = demandScoreApi;

export default demandScoreApi;