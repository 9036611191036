import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, DialogContent, DialogTitle, DialogActions, Stack, Tooltip, Alert } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { SwitchAccountOutlined } from '@mui/icons-material';
import { getIntegrationLogoUrl } from '../../../CustomerManagement/Integrations/Helpers';
import { getFileIcon, getFolderIcon } from './helpers';
import { useGetDocumentsForMatterQuery, useGetIntegrationMetadataQuery } from '../../../services/integrations/integrationsApi';
import { resetIntegrationDocumentSelectionModal, setSelectedRowsForDocumentsUploaded } from '../../../redux/slices/integrationsSlice';
import { useGetAllFilesByDocumentIdQuery } from '../../../services/documentFiles/documentFilesApi';
import { setIntegrationSearch } from '../../../redux/slices/globalDialogueSlice';
import { setDocumentUploaderList } from '../../../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../../CommonComponents/Title';
import { DOCUMENT_UPLOAD_FILE_TYPES_LONG_VERBOSE, MAX_FILE_SIZE } from '../../../common-document';

// Maximum number of items that can be selected
const MAX_SELECTABLE_ITEMS = 30;

const dataGridStyles = {
    border: '1px solid',
    borderColor: 'divider',
    flex: 1,
    '& .MuiDataGrid-cell': {
        borderBottom: '1px solid #f0f0f0',
    },
    '& .Mui-disabled': {
        backgroundColor: '#f5f5f5',
        cursor: 'not-allowed',
        opacity: 0.7,
    },
};

const DocumentSelectionModal = () => {
    const dispatch = useDispatch();

    const { documentUploaderList } = useSelector((state) => state.DemandDom);
    const documentData = useSelector((state) => state.Document.documentData);
    const documentId = documentData?.documentId;

    const {
        integratedCase: { id: matterId, claimNumber },
        selectedRowsForDocumentsUploaded: selectedRows,
        integrationDocumentSelectionModal: { data: { allowChangingLinkedCase } }
    } = useSelector((state) => state.Integrations);

    const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
    const { displayName: integrationName, integrationId,  displayLabels = [] } = integrationMetadata;
    const { data: documents = [], isLoading } = useGetDocumentsForMatterQuery({ matterId, integrationId });

    const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
        skip: !documentId,
        refetchOnMountOrArgChange: true,
    });

    const [initialChecked, setInitialChecked] = useState([]);
    const prevouslyPersistedIntegrationFiles = documentFiles.filter(df => df.fileEntityData?.sourceSystemId === integrationId);

    useEffect(() => {
        setInitialChecked(documents
            .filter(doc =>
                (documentUploaderList.some(du => du.externalFileId === doc.id))
            )
            .map(doc => doc.id));

        return () => {
            setInitialChecked([]);
        }
    }, [documents, documentUploaderList]);

    useEffect(() => {
        dispatch(setSelectedRowsForDocumentsUploaded(initialChecked));
    }, [dispatch, initialChecked]);

    const getFileValidationStatus = useCallback((file) => {
        const isTypeAllowed = DOCUMENT_UPLOAD_FILE_TYPES_LONG_VERBOSE.includes(file.contentType);
        const isSizeAllowed = file.size <= MAX_FILE_SIZE;

        let message = '';
        if (!isTypeAllowed) {
            message = `File type ${file.contentType} is not supported`;
        } else if (!isSizeAllowed) {
            const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
            const maxSizeMB = (MAX_FILE_SIZE / (1024 * 1024)).toFixed(2);
            message = `File size ${fileSizeMB}MB exceeds the maximum allowed size of ${maxSizeMB}MB`;
        }

        return {
            isValid: isTypeAllowed && isSizeAllowed,
            message
        };
    }, []);

    // Comprehensive validation including file constraints and selection limits
    const getRowValidationStatus = useCallback((params) => {
        const { id } = params.row;

        // Check file validation first
        const fileValidation = getFileValidationStatus(params.row);
        if (!fileValidation.isValid) {
            return fileValidation;
        }

        // Check if file is already uploaded
        if (prevouslyPersistedIntegrationFiles.some(df => df.fileEntityData?.externalFileId === id)) {
            return {
                isValid: false,
                message: 'This document has already been uploaded and saved'
            };
        }

        // Check if already selected
        if (initialChecked.includes(id)) {
            return {
                isValid: false,
                message: 'This document has already been selected'
            };
        }

        // Check maximum selection limit
        const currentSelectionsCount = selectedRows.length;
        const wouldExceedMax = currentSelectionsCount >= MAX_SELECTABLE_ITEMS && !selectedRows.includes(id);

        if (wouldExceedMax) {
            const availableSlots = MAX_SELECTABLE_ITEMS - currentSelectionsCount;
            return {
                isValid: false,
                message: availableSlots <= 0
                    ? `Maximum selection limit of ${MAX_SELECTABLE_ITEMS} items reached`
                    : `Can only select ${availableSlots} more item${availableSlots !== 1 ? 's' : ''}`
            };
        }

        return { isValid: true, message: '' };
    }, [initialChecked, selectedRows, getFileValidationStatus]);

    // Wrap content with tooltip if validation fails
    const wrapWithTooltipIfNeeded = (content, params) => {
        const validation = getRowValidationStatus(params);
        return validation.isValid ? (
            content
        ) : (
            <Tooltip
                title={validation.message}
            >
                <Box sx={{
                    width: '100%',
                    opacity: validation.isValid ? 1 : 0.7,
                    cursor: validation.isValid ? 'pointer' : 'not-allowed'
                }}>
                    {content}
                </Box>
            </Tooltip>
        );
    };

    const getColumnText = () => displayLabels.find((label) => label.labelId === 'folder_column_text')?.labelText ?? '';

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => {
                const content = (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {getFileIcon(params.row.name)}
                        {params.value}
                    </Box>
                );
                return wrapWithTooltipIfNeeded(content, params);
            },
        },
        {
            field: 'folder',
            headerName: getColumnText(),
            flex: 1,
            renderCell: (params) => {
                const content = (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {getFolderIcon(params.value)}
                        {params.value}
                    </Box>
                );
                return wrapWithTooltipIfNeeded(content, params);
            },
        },
        {
            field: 'receivedAt',
            headerName: 'Uploaded',
            flex: 1,
            renderCell: (params) => {
                const content = new Date(params.value).toLocaleString();
                return wrapWithTooltipIfNeeded(content, params);
            },
        },
    ];

    const handleRowSelectionChange = useCallback((newSelection) => {
        //only allow selection of up to MAX_SELECTABLE_ITEMS
        const newSelectionWithMaxLimits = newSelection.slice(-MAX_SELECTABLE_ITEMS);
        dispatch(setSelectedRowsForDocumentsUploaded(newSelectionWithMaxLimits));
    }, [dispatch]);

    // Determine if a row can be selected
    const isRowSelectable = useCallback((params) => {
        const validation = getRowValidationStatus(params);
        return validation.isValid;
    }, [getRowValidationStatus]);

    const handleClose = () => {
        dispatch(resetIntegrationDocumentSelectionModal());
    };

    const handleCancel = () => {
        dispatch(setSelectedRowsForDocumentsUploaded(initialChecked));
        handleClose();
    };

    const handleUpload = (selectedRows) => {
        dispatch(setDocumentUploaderList(
            documentUploaderList
                .filter(doc => !doc.isExternalFile)
                .concat(
                    documents
                        .filter(doc => selectedRows.includes(doc.id))
                        .filter(doc => !documentFiles.map(df => df.fileEntityData?.externalFileId || '').includes(doc.id))
                        .map(doc => ({
                            ...doc,
                            type: doc.contentType,
                            isExternalFile: true,
                            externalFileId: doc.id,
                            externalFileReceivedDt: doc.receivedAt,
                            externalFileUploadedDt: doc.createdAt,
                            sourceSystemId: integrationId,
                        }))
                )
        ));

        handleClose();
    };

    const handleSearch = () => {
        dispatch(setIntegrationSearch({ open: true, data: { integrationId, integrationName } }));
        handleClose();
    };

    return (
        <>
            <DialogTitle>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                >
                    <img
                        src={getIntegrationLogoUrl(integrationId)}
                        alt={integrationName}
                        style={{
                            height: 32,
                        }}
                    />
                    <Title>
                        Documents
                    </Title>
                </Stack>
            </DialogTitle>

            <DialogContent
                sx={{ mt: 2 }}
            >
                <Stack spacing={1}>
                    {selectedRows.length >= MAX_SELECTABLE_ITEMS &&
                        <Alert severity="warning">
                            {`You have reached the limit of ${MAX_SELECTABLE_ITEMS} documents to upload at a time`}
                        </Alert>
                    }
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                    >
                        <Title>
                            {claimNumber}
                        </Title>
                        {allowChangingLinkedCase && (
                            <Button
                                color="secondary"
                                startIcon={<SwitchAccountOutlined />}
                                onClick={handleSearch}
                            >
                                Select different matter in {integrationName}
                            </Button>
                        )}
                    </Stack>
                    <Box
                        sx={{
                            height: 500
                        }}
                    >
                        <DataGridPro
                            loading={isLoading}
                            rows={documents}
                            columns={columns}
                            checkboxSelection
                            rowSelectionModel={selectedRows}
                            onRowSelectionModelChange={handleRowSelectionChange}
                            isRowSelectable={isRowSelectable}
                            sx={{
                                ...dataGridStyles,
                                '& .MuiDataGrid-columnHeaderCheckbox': {
                                    visibility: documents.length > MAX_SELECTABLE_ITEMS ? 'hidden' : 'visible'
                                }
                            }}
                        />
                    </Box>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled={selectedRows.length === initialChecked.length}
                    onClick={() => handleUpload(selectedRows)}
                >
                    Upload documents
                </Button>
            </DialogActions>
        </>
    );
};

export default DocumentSelectionModal;
