import React, { useCallback } from 'react';
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { Box, Typography, Radio } from '@mui/material';

const slots = {
  baseCheckbox: Radio,
};

const slotProps = {
  baseCheckbox: { color: 'secondary' },
};

const SearchResultsTable = ({
  rows = [],
  isLoading = false,
  shouldLimitRows = false,
  rowSelectionModel,
  setRowSelectionModel,
}) => {
  const limitedRows = shouldLimitRows ? rows.slice(0, 50) : rows;

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => <></>,
    },
    {
      field: 'id',
      headerName: 'Display number',
      width: 150,
      minWidth: 100,
      renderCell: ({ row }) => <Typography variant="tableP1">{row.claimNumber}</Typography>,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      minWidth: 100,
      valueGetter: ({ row }) => row.claimInfo?.claimant?.name.split(' ')[0],
      renderCell: ({ row }) => {
        const firstName = row.claimInfo?.claimant?.name.split(' ')[0];
        return <Typography variant="tableP1">{firstName}</Typography>;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      minWidth: 100,
      valueGetter: ({ row }) => row.claimInfo?.claimant?.name.split(' ')[1],
      renderCell: ({ row }) => {
        const lastName = row.claimInfo?.claimant?.name.split(' ')[1];
        return <Typography variant="tableP1">{lastName}</Typography>;
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      minWidth: 125,
      renderCell: ({ row }) => <Typography variant="tableP1">{row.description}</Typography>,
    },
  ];

  const handleRowSelectionChange = useCallback((newRowSelectionModel) => {
    if (newRowSelectionModel.length > 1) {
      newRowSelectionModel.shift();
    }
    setRowSelectionModel(newRowSelectionModel);
  }, []);

  return (
    <Box sx={{ mt: 2, height: 400 }}>
      <DataGridPro
        rows={limitedRows}
        columns={columns}
        loading={isLoading}
        checkboxSelection
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={handleRowSelectionChange}
        disableMultipleRowSelection
        disableColumnPinning
        disableColumnReorder
        disableColumnSelector
        slots={slots}
        slotProps={slotProps}
      />
    </Box>
  );
};

export default SearchResultsTable;
